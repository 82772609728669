import { getAppLayout } from 'components/layouts/AppLayout';
import ArticleDetail from 'components/pages/article/ArticleDetail';
import { useApiClientV2 } from 'hooks/useApiClient';
import { Suspense } from 'react';
import { AliasRequest } from 'types/apiRequests';
import { FetchAliasType } from 'types/apiResponseTypes';
import { NextPageWithLayout } from './_app';

const AliasPageContent = () => {
  const { data } = useApiClientV2<FetchAliasType>(new AliasRequest({
    url: window.location.pathname
  }));

  return (
    <Suspense>
      <ArticleDetail
        pageId={ data.pageId }
        pageModule={ data.pageModule }
      />
    </Suspense>
  );
}

const AliasPage: NextPageWithLayout = () => (
  <Suspense>
    <AliasPageContent />
  </Suspense>
)

AliasPage.getLayout = getAppLayout;

export default AliasPage;
