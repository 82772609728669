import axios from 'axios';
import FlatButton from 'components/atoms/button/FlatButton';
import CustomFormSet from 'components/shareds/form/CustomFormSet';
import NavigationActions from 'components/shareds/NavigationActions';
import { useApiClientV2 } from 'hooks/useApiClient';
import parse from 'html-react-parser';
import { ReactElement, useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PageContentStyled from 'styles/PageBodyStyled';
import { fontSize, mediaDown } from 'styles/mixins';
import { colors, grayScales } from 'styles/variables';
import { ArticlePageResultType } from 'types/apiResponseTypes';
import ArticleHeader from './ArticleHeader';
import { ArticleCustomFormEntryRequest, ArticlePageRequest } from 'types/apiRequests';

type ArticleDetailType = {
  pageId: number;
  pageModule: string;
  hideMeta?: boolean;
}

type FileHashType = {
  [key: string]: {
    src: string;
    name: string;
    contentType: string;
    width: string;
    height: string;
  }
}

const PDFViewStyled = styled.div`
  .pdf-mobile {
    display: none;
  }

  ${mediaDown('desktop', css`
    iframe {
      display: none;
    }

    .pdf-mobile {
      display: block;
    }
  `)}
`;

const ArticleDetailStyled = styled.div`
    .page {
      padding: 0 40px;

      ${mediaDown('desktop', css`
        padding: 0;
      `)}

      &__header {
        &--title {
          ${fontSize(32)}
          font-weight: 700;
          margin: 15px 0;
        }

        &--attribute-category {
          ${fontSize(24)}
          color: ${colors.primary};
          font-weight: 700;
        }

        &--attribute-divider {
          ${fontSize(24)}
          color: ${grayScales.gs700};
          font-weight: 400;
        }

        &--attribute-date {
          ${fontSize(24)}
          color: ${grayScales.gs700};
          font-weight: 500;
        }

        &--attribute-time {
          ${fontSize(12)}
          color: ${grayScales.gs700};
          font-weight: 500;
        }
      }

      &__thumbnail {
        img {
          width: 100%;
        }
      }

      &__body {
        background-color: ${colors.white};
        padding: 30px;
        ${fontSize(16)}

        &--form {
          border-top: 1px solid #ccc;
          margin-top: 30px;
          padding-top: 30px;
        }

        &--button {
          display: flex;
          justify-content: center;
          margin-top: 30px;
        }
      }

      &__footer {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;

        button:first-child,
        a:first-child {
          margin-bottom: 30px;
        }
      }
    }
  `,
  ArticleDetail = (props: ArticleDetailType): ReactElement => {
    const [fileHash, setFileHash] = useState<FileHashType>({}),
      [requested, setRequested] = useState<boolean>(false),
      { data: articlePage } = useApiClientV2<ArticlePageResultType>(new ArticlePageRequest({
        page: props.pageId,
        pageModule: props.pageModule
      }));

    useLayoutEffect(() => {
      const scriptList = document.querySelectorAll('#page-body script');

      scriptList.forEach((script) => {
        const element = document.createElement('script');
        element.type = 'text/javascript';
        const code = document.createTextNode(script.textContent ?? '');
        element.appendChild(code);

        document.body.appendChild(element);
      });
    }, [articlePage.body]);

    useLayoutEffect(() => {
      (async () => {
        // TODO: Refの方が確実かも
        const iframeList: NodeListOf<HTMLIFrameElement> = document.querySelectorAll('#page-body iframe');
        const tmpFileHash: FileHashType = {};

        await Promise.all(
          Array.from(iframeList).map(iframe => axios.get(iframe.src)
            .then(res => {
              tmpFileHash[iframe.src] = {
                contentType: res?.headers['content-type'] ?? '',
                height: iframe.height,
                name: iframe.title || iframe.name || 'PDFファイル',
                src: iframe.src,
                width: iframe.width
              };
            })
            .catch(() => null)
          )
        );
        setFileHash(tmpFileHash);
      })();
    }, [articlePage.body])

    return (
      <ArticleDetailStyled>
        <div className='page'>
          <div className='page__header'>
            <ArticleHeader
              categoryName={articlePage.categoryName}
              pageTitle={articlePage.title}
              date={new Date(articlePage.publishedAt.replaceAll('-', '/'))}
              hideMeta={ props.hideMeta }
            />
          </div>
          {
            articlePage.thumbnailUrl.length < 1 ? '' : (
              <div className='page__thumbnail'>
                <img src={articlePage.thumbnailUrl} alt='article_image' />
              </div>
            )
          }
          <div className='page__body'>
            <PageContentStyled>
              <div id='page-body' className='page__body--content'>
                {parse(articlePage.body, {
                  replace: (node) => {
                    const iframeFile = node as unknown as { name: string; attribs: { src: string }; };
                    if (iframeFile.name === 'iframe') {
                      const key = iframeFile.attribs.src.trim();
                      const file = fileHash[key];

                      if (!file) {
                        return;
                      }

                      return (
                        <PDFViewStyled>
                          <iframe
                            src={file.src}
                            width={file.width}
                            height={file.height}
                          />
                          <div className='pdf-mobile'>
                            <div className='embed-pdf-link__name'>{ file.name }</div>
                            <FlatButton
                              type='primary'
                              title='PDFファイルを表示する'
                              didClickButton={() => {
                                const a = document.createElement('a');
                                a.href = file.src;
                                a.target = '_blank';
                                a.click();
                              }}
                            />
                          </div>
                        </PDFViewStyled>
                      );
                    }
                  }
                })}
              </div>
            </PageContentStyled>
            {(articlePage.formItems.length > 0 && !articlePage.formDisabled) && (
              <CustomFormSet
                request={new ArticleCustomFormEntryRequest({
                  pageId: props.pageId,
                  path: window.location.pathname,
                })}
                items={articlePage.formItems}
                willSubmitForm={() => {
                  setRequested(true);
                }}
                didSubmitForm={() => setRequested(false)}
              >
                <div className='page__body--button'>
                  <FlatButton
                    type='primary'
                    title={requested ? '送信中...' : '送信する'}
                    isSubmit={true}
                    disabled={requested}
                  />
                </div>
              </CustomFormSet>
            )}
          </div>
          <div className='page__footer'>
            <NavigationActions
              backPath={articlePage.listPath}
              adminUrl={articlePage.adminUrl}
            />
          </div>
        </div>
      </ArticleDetailStyled>
    );
  }

export default ArticleDetail;
