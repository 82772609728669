import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { colors, grayScales } from 'styles/variables';
import { dayOfWeek } from 'utils/tools';

const ArticleHeaderStyled = styled.div`
  .article-header {
    &__top {
      &--category {
        ${fontSize(24)}
        color: ${colors.primary};
        font-weight: 700;
      }

      &--divider {
        ${fontSize(24)}
        color: ${grayScales.gs700};
        font-weigth: 400;
      }

      &--date {
        ${fontSize(24)}
        color: ${grayScales.gs700};
        font-weigth: 500;
      }

      &--time {
        ${fontSize(12)}
        color: ${grayScales.gs700};
        font-weigth: 500;
      }
    }

    &__bottom {
      ${fontSize(32)}
      font-weight: 700;
      margin: 15px 0;
    }
  }
`;

const ArticleHeader = (props: {
  categoryName: string;
  pageTitle: string;
  date?: Date;
  hideMeta?: boolean;
}) => {
  let formattedDate = '';
  let formattedTime = '';

  if (props.date !== undefined) {
    formattedDate = [
      props.date.getFullYear(),
      props.date.getMonth() + 1,
      props.date.getDate()
    ].join('/');
  
    formattedTime = [
      String(props.date.getHours()).padStart(2, '0'),
      String(props.date.getMinutes()).padStart(2, '0')
    ].join(':');  
  }

  return (
    <ArticleHeaderStyled>
      <div className='article-header'>
        {!props.hideMeta &&
          <div className='article-header__top'>
            <span className='article-header__top--category'>{props.categoryName}</span>
            {
              props.date === undefined ? '' : (
                <>
                  <span className='article-header__top--divider'>｜</span>
                  <span className='article-header__top--date'>{formattedDate}</span>
                  <span className='article-header__top--time'>
                    （{dayOfWeek(props.date)}）{formattedTime}
                  </span>
                </>
              )
            }
          </div>
        }
        <div className='article-header__bottom'>
          {props.pageTitle}
        </div>
      </div>
    </ArticleHeaderStyled>
  );
}

export default ArticleHeader;